.footer {
  border-top: 1px solid $border;
  // border-bottom: 1px solid $border;
  color: #afb2ae;
  margin-top: 60px;

  @media (min-width: $largebp) {
    margin-top: 120px;
  }

  h2 {
    color: $green;
    font-size: 21px;
    text-transform: uppercase;
    margin-bottom: 10px;

    @media (min-width: $largebp) {
      font-size: 24px;
    }
  }
}

.footer-flex {
  @media (min-width: $largebp) {
    display: flex;
  }
}

.footer-left {
  padding: 60px 100px 60px 0;

  @media (min-width: $largebp) {
    width: calc(33.33333% - 21.333px);
    max-width: 480px;
    border-right: 1px solid $border;
  }

  p {
    line-height: 1.55;
  }
}

.footer-social {
  margin-top: 40px;

  li + li {
    margin-top: 30px;
  }
}

.footer-social-anchor {
  display: flex;
  color: #afb2ae;
  transition: 0.2s color;

  &:hover {
    color: $white;
  }
}

.footer-social-anchor-left {
}

.footer-social-anchor-right {
  flex: 1;
  margin-left: 15px;

  strong {
    color: $white;
    display: block;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    margin: -2px 0 0 10px;
  }
}

.footer-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 60px;

  @media (min-width: $largebp) {
    padding-top: 0px;
  }

  &:after {
    content: "";
    width: 200vw;
    top: -1px;
    left: -50vw;
    background: $border;
    position: absolute;
    height: 1px;

    @media (min-width: $largebp) {
      display: none;
    }
  }
}

.footer-right-top {
  display: flex;

  @media (min-width: $largebp) {
    padding: 60px 0 60px 40px;
  }

  h2 {
    flex: 1;
  }

  svg {
    width: 80px;

    @media (min-width: $largebp) {
      width: 110px;
    }
  }
}

.footer-right-mail {
  margin-top: auto;

  @media (min-width: $largebp) {
    padding: 0 0 0 40px;
  }
}

.footer-right-mail-fields {
  display: flex;
  margin-top: 20px;

  input {
    flex: 1;
    line-height: 68px;
    border: 1px solid #f7f4f2;
    padding: 0 24px;
    font-family: $mono;
    color: #f7f4f2;
    transition: 0.2s border-color;
    border-right: none;

    &:focus {
      border-color: $white;
    }

    &::placeholder {
      color: rgba(247, 244, 242, 0.55);
    }
  }

  button {
    display: flex;
    width: 70px;
    height: 70px;
    padding-top: 2px;
    background: #f7f4f2;
    color: $darkGray;
    align-items: center;
    justify-content: center;
    transition: 0.2s background-color;

    &:hover {
      background-color: $green;
    }
  }
}

.footer-right-bottom {
  margin-top: 60px;
  position: relative;
  padding: 40px 0;

  @media (min-width: $smallbp) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: $largebp) {
    font-size: 18px;
    margin-top: 40px;
    border-top: 1px solid $border;

    padding: 40px 0 40px 40px;
  }

  &:after {
    content: "";
    width: 200vw;
    left: -50vw;
    top: -1px;
    background: $border;
    position: absolute;
    height: 1px;

    @media (min-width: $largebp) {
      width: 100vw;
      left: 100%;
    }
  }

  p {
    color: #f7f4f2;
    order: -1;
    margin-top: 20px;

    @media (min-width: $smallbp) {
      margin-top: 0;
    }
  }

  ul {
    display: inline-flex;
    align-items: center;
  }

  li + li {
    margin-left: 40px;
  }

  a {
    color: #f7f4f2;

    &:hover {
      color: $green;
    }
  }
}

.klaviyo_messages {
  font-size: 13px;
  margin-top: 15px;
}

.footer-globe {
  width: 30px;
  display: block;
}
