
.pageTitle {
  color: var(--offwhite);
  opacity: 48%;
  text-transform: uppercase;
  font-family: "Expanded", sans-serif;
  font-weight: bold;
  margin: 0;

  @media (max-width: 1000px) {
    font-size: 15px;
  }

  &:not(:last-child) {
    margin-right: 40px;

    @media (max-width: 1000px) {
      display: none;
    }
  }
}

.galleryWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  @media (max-width: 1000px) {
    margin-bottom: 20px;
    height: calc(100vh - 40px);
  }
}

.galleryContent {
  display: flex;
  flex: 1 0 auto;
  align-items: stretch;

  @media (max-width: 925px) {
    flex-direction: column;;
  }
}

.tokens {
  flex: 1 1 auto;  
  position: relative;

  @media (max-width: 925px) {
    max-width: none;
  }
}

.tokensInner {
  display: grid;
  overflow-y: auto;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 1px;
  background-color: var(--offwhite);

  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 850px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.filtersWrapper {
  flex: 0 0 350px;
  border-right: 1px solid var(--offwhite);
  position: relative;

  @media (max-width: 925px) {
    position: static;
    flex: 0 0 auto;
    z-index: 1000;
    border-right: none;
  }
}

.placeholder {
  background-color: var(--black);
}

.emptyPlaceholder {
  background-color: var(--black);
  grid-column-start: 1;
  grid-column-end: 12;
}

.pageHeader {
  border-bottom: 1px solid var(--offwhite);
  padding: 50px 40px;

  @media (max-width: 1000px) {
    padding: 20px;
  }

  h1 {
    font-size: 50px;
    line-height: 1em;
    margin-bottom: 20px;

    @media (max-width: 1000px) {
      font-size: 35px;
    }

    @media (max-width: 600px) {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  p {
    max-width: 973px;
    font-size: 18px;
    line-height: 1.3em;

    @media (max-width: 1000px) {
      font-size: 16px;
    }

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
}