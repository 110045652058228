.button {
  display: inline-flex;
  text-align: center;
  line-height: 45px;
  text-transform: uppercase;
  font-size: 15px;
  padding: 3px 24px 0;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 25px;
  transition: 0.2s background-color, 0.2s color, 0.2s border-color;

  &.fullWidth {
    display: block;
    width: 100%;
  }

  &.green {
    border: 1px solid $green;
    background: $green;
    color: $darkGray;
  }

  &.green:hover {
    border: 1px solid $greenHover;
    background: $greenHover;
  }

  &.line-green {
    background-color: transparent;
    color: $green;
    border: 1px solid $green;
  }

  &.line-green:hover {
    background: $green;
    border: 1px solid $green;
    color: $darkGray;
  }

  &.line-white {
    background-color: transparent;
    color: $white;
    border: 1px solid $white;
  }

  &.line-white:hover {
    background: $white;
    border: 1px solid $white;
    color: $darkGray;
  }

  & > * + * {
    margin-left: 16px;
  }

  svg {
    margin-top: -2px;
  }
}
