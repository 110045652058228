.title {
  margin-bottom: 20px;
  font-size: 20px;

  &.tight {
    margin-bottom: 8px;
  }
}

.description {
  max-width: 480px;
  margin-bottom: 40px;

  &.tight {
    line-height: 1.25;
  }
}
