.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);

  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;

  transition: opacity 125ms ease-in;

  &.open {
    opacity: 1;
    pointer-events: all;
  }
}

.modal {
  padding: 72px 64px;
  background-color: #000;
  border-radius: 2px;
  border: 1px solid #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 24px;

  max-height: calc(100vh - 40px);
  overflow-y: auto;

  @media (max-width: 600px) {
    margin-left: 20px;
    margin-right: 20px;

    padding: 40px 32px;
  }
}

.closeButton {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 25px;
  cursor: pointer;
}
