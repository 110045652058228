.header {
  padding: 24px 0;

  @media (min-width: $largebp) {
    padding: 32px 0;
  }
}

.header-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.header-logo {
  display: block;
  position: relative;
  z-index: 2;

  @media (min-width: $largebp) {
    margin-right: 40px;
  }

  @media (min-width: $xlargebp) {
    margin-right: 80px;
  }
}

.header-nav {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 70px;
  //padding-bottom: 20px;
  background: $bg;
  width: 100%;
  opacity: 0;
  left: -9999px;
  transition: $fadeOff;

  @media (min-width: $largebp) {
    display: flex;
    align-items: center;
    position: static;
    opacity: 1;
    background: transparent;
    padding: 0;
    flex: 1;
  }

  .navActive & {
    opacity: 1;
    left: 0;
    transition: $fadeOn;
  }
}

.header-nav-item {
  display: block;
  padding: 20px 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  @media (min-width: $largebp) {
    padding: 0;
    border: none;
  }

  & + & {
    @media (min-width: $largebp) {
      margin-left: 40px;
    }

    @media (min-width: $xlargebp) {
      margin-left: 80px;
    }
  }

  &.header-button {
    @media (min-width: $largebp) {
      margin-left: auto;
    }
  }
}

.header-nav-anchor {
  color: $lightBlue;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  transition: 0.2s color;

  &:hover {
    color: $green;
  }
}

.header-button {
  .button {
    width: 100%;

    @media (min-width: $largebp) {
      width: auto;
    }
  }

  @media (min-width: $smallbp) {
    width: auto;
  }
}

.hamburger {
  width: 22px;
  height: 20px;
  display: block;
  position: relative;
  margin-left: auto;
  z-index: 2;

  @media (min-width: $largebp) {
    display: none;
  }

  .hamburger-bar {
    width: 22px;
    height: 2px;
    position: absolute;
    left: 50%;
    background: $white;
    display: block;
    transform: translateX(-50%);

    &:nth-of-type(1) {
      top: 2px;
      opacity: 1;
      transition: 0.2s transform, 0.2s top, 0.2s opacity;

      .navActive & {
        top: 50%;
        opacity: 0;
        transform: translate(-50%, -50%);
      }
    }

    &:nth-of-type(2) {
      .navActive & {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    &:nth-of-type(3) {
      .navActive & {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      top: 50%;
      transform: translate(-50%, -50%);
      transition: 0.2s transform;
    }

    &:nth-of-type(4) {
      bottom: 2px;
      opacity: 1;
      transition: 0.2s transform, 0.2s bottom, 0.2s opacity;

      .navActive & {
        bottom: 50%;
        opacity: 0;
        transform: translate(-50%, -50%);
      }
    }
  }
}
