

.input {
  border: 1px solid #5E5961;
  padding: 16px;
  font-size: 18px;
  font-family: "Mono";
  color: var(--offwhite);
  width: 100%;

  &::placeholder {
    color: rgba(255, 255, 255, 0.55);
  }
}

.error {
  color: var(--formError);
  margin-top: 4px;
}

.select {
  @extend .input;
  background-color: var(--black);

  padding-right: 32px;

  option:not(:disabled) {
    color: var(--offwhite);
  }

  &:invalid {
    color: rgba(255, 255, 255, 0.55);
  }
}

.selectContainer {
  position: relative;
}

.caret {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 7px;
  background: url('../icons/caret_down.svg') center center/contain no-repeat;
  pointer-events: none;
}