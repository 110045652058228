
.detailsWrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}



.main {
  display: grid;
  grid-template-columns: 9fr 8fr 8fr;
  grid-gap: 1px;
  background-color: var(--offwhite);
  border-bottom: 1px solid var(--offwhite);

  @media (max-width: 1650px) {
    grid-template-columns: repeat(2, 1fr);

    .activity {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  @media (max-width: 900px) {
    grid-template-columns: minmax(0, 1fr);

    .image {
      order: -1;
      min-height: 75vw;
    }

    .activity {
      grid-column-end: 1;
    }
  }

  & > div {
    background-color: var(--black);
  }
}

.image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  max-width: 100%;
}

.info {
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 50px;
    line-height: 0.9em;
    margin-bottom: 10px;
    small {
      font-size: 0.4em;
      position: relative;
      top: -8px;
    }

    @media (max-width: 900px) {
      font-size: 22px;

      small {
        font-size: 11px;
        top: 0;
      }
    }

  }
}

.infoContent {
  padding: 40px;

  @media (max-width: 900px) {
    padding: 32px 20px;
  }
}

.infoSocialLink {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 50px;

  img {
    margin-right: 8px;
  }

  @media (max-width: 900px) {
    margin-bottom: 16px;
  }
}

.splitRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin: 20px 0;

  @media (max-width: 900px) {
    grid-gap: 8px;
    margin: 8px 0;
  }
}

.profileButton {
  text-align: center;
  justify-content: center;
  height: 60px;
  font-size: 13px;

  &.connectButton {
    background-color: var(--offwhite);
    margin-left: 8px;
  }

  &.whiteWide {
    background-color: var(--offwhite);
    width: 100%;
  }

  @media (max-width: 900px) {
    font-size: 11px;
    line-height: 15px;
  }
}

.badgeGrid {
  flex: 1 0 auto;
  border-top: 1px solid var(--offwhite);
}

.tokens {

}

.tokensInner {
  display: grid;
  overflow-y: auto;


  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1px;
  background-color: var(--offwhite);

  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.placeholder {
  background-color: var(--black);
}

.emptyPlaceholder {
  background-color: var(--black);
  grid-column-start: 1;
  grid-column-end: 12;
}

.tokensTitle {
  font-size: 15px;
  padding: 30px 40px;
  margin: 0;
  border-bottom: 1px solid var(--offwhite);

  @media (max-width: 900px) {
    padding: 20px;
  }
}