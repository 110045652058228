
.icon {
  margin-bottom: 40px;
  height: 50px;
}

.closeButton {
  width: 100%;
}

.split {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 25px;
  align-items: start;
  margin: 40px 0;
  font-size: 18px;

  :first-child:last-child {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;

    & > :last-child {
      margin-top: 25px;
    }
  }
}

.sectionTitle {
  text-transform: uppercase;
  font-family: 'Expanded', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
}

.mono {
  font-family: 'Mono';
  line-height: 1.33;
}