
.container {
  padding: 40px;
  overflow-y: auto;

  transition: opacity 150ms ease-in;

  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  @media (max-width: 925px) {
    background-color: var(--black);

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }
}


.title {
  text-transform: uppercase;
  font-family: "Expanded", sans-serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 1em;
  color: var(--offwhite);
  opacity: 55%;
  margin-bottom: 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .closeX {
    width: 1em;
    height: 1em;
    display: none;
  }

  @media (max-width: 925px) {
    opacity: 1;
    font-size: 20px;
    // line-height: 15px;

    .closeX {
      cursor: pointer;
      display: block;
      width: 22px;
      height: 22px;
      transform: rotate(45deg);
    }

    padding-bottom: 20px;
    border-bottom: 1px solid var(--offwhite);
  }
}

.filterValues {
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;

  a {
    color: #fff;
    margin: 10px 0;
    font-size: 15px;
    display: flex;
    align-items: center;
    line-height: 15px;
    user-select: none;

    .closeX {
      height: 20px;
      width: 20px;
      transform: rotate(45deg);
      margin-left: 8px;
      opacity: 0;
    }

    &.selected {
      .closeX {
        opacity: 1;
      }
    }
  }

}

.filterToggle {
  cursor: pointer;
  font-family: "Expanded", sans-serif;
  user-select: none;
  margin: 15px 0;
  font-size: 15px;
  font-weight: bold;

  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
}
.mobileToggle {
  @extend .filterToggle;
  display: none;

  line-height: 15px;
  
  svg {
    height: 28px;
    position: relative;
    top: -2px;
  }

  @media (max-width: 925px) {
    margin: 0;
    padding: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--offwhite);  
  }
}

.resetFilters {
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  padding: 15px 0;
  height: auto;
  background-color: rgba(241, 230, 243, 0.18);
  color: rgba(241, 230, 243, 0.9);
  transition: background-color 150ms ease-in;

  &:hover {
    background-color: rgba(241, 230, 243, 0.32);
  }
}