:root {
  --black: #050604;
  --green: #2bff88;
  --greenHover: #28f07f;
  --formError: #ff3d2b;
  --offwhite: #f1e6f3;
  --offwhiteHover: #ddd3df;
  --gray: rgba(241, 230, 243, 0.55);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  font-family: "Expanded", sans-serif;
}

a {
  text-decoration: none;
  color: var(--green);
}

li {
  list-style: none;
}

input,
select,
button,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: transparent;
  outline: none;
  font-family: "Extended", sans-serif;
  font-size: 16px;
  border-radius: 0;
}

input[type="email"],
input[type="text"],
input[type="password"],
input[type="search"] {
  display: block;
  width: 100%;
}

a,
button,
label,
input[type="submit"],
input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}

img,
svg,
video {
  display: block;
  max-width: 100%;
}

.wrapper {
  opacity: 0;
  transition: 2s opacity;
}

.wrapper.ready {
  opacity: 1;
}

/*
  Mobile Top
*/

.mobile-top {
  border-bottom: 1px solid var(--offwhite);
  padding: 0 30px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 1000px) {
  .mobile-top {
    display: none;
  }
}

.mobile-top-button {
  background: var(--offwhite);
  height: 36px;
  display: inline-flex;
  padding: 0 16px;
  font-size: 11px;
  text-transform: uppercase;
  font-family: "Expanded", sans-serif;
  font-weight: bold;
  color: var(--black);
  align-items: center;
  padding-top: 2px;
}

.mobile-top-button:hover {
  background: var(--green);
}

/*
  Left
*/

.left {
  padding: 35px 30px;
  border-bottom: 1px solid var(--offwhite);
  overflow: hidden;
}

@media (min-width: 1000px) {
  .left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: none;
    border-right: 1px solid var(--offwhite);
    padding: 55px 60px;
    position: relative;
  }
}

.left-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
}

@media (min-width: 1000px) {
  .left-lines {
    display: block;
  }
}

.left-card {
  width: 300px;
  margin: 0 0 -40px 20px;
  z-index: 2;
  position: relative;
}

@media (min-width: 1000px) {
  .left-card {
    width: auto;
    height: 50vh;
    margin-left: auto;
    margin-bottom: -100px;
  }
}

@media (min-width: 1200px) {
  .left-card {
    margin-bottom: -140px;
  }
}

@media (min-width: 1600px) {
  .left-card {
    margin-bottom: -180px;
  }
}

.left-content {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
}

.left-content h1 {
  font-size: 30px;
}

@media (min-width: 1000px) {
  .left-content h1 {
    font-size: 50px;
  }
}

@media (min-width: 1200px) {
  .left-content h1 {
    font-size: 60px;
  }
}

@media (min-width: 1440px) {
  .left-content h1 {
    font-size: 72px;
  }
}

.left-content .availability {
  color: var(--gray);
  font-size: 14px;
  margin: 10px 0;
}

@media (min-width: 1000px) {
  .left-content .availability {
    font-size: 18px;
    margin: 20px 0 0 0;
  }
}

.left-content-buttons {
  order: 10;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1700px) {
  .left-content-buttons {
    order: initial;
    flex-direction: row;
  }
}

@media (max-width: 1000px) and (min-width: 700px) {
  .left-content-buttons {
    order: initial;
    flex-direction: row;
  }
}

.left-content-button {
  flex: 1 0 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--green);
  color: var(--black);
  padding: 0 20px;
  font-family: "Expanded", sans-serif;
  font-weight: bold;
  font-size: 15px;
  padding-top: 2px;
  cursor: pointer;
}

.left-content-button.buy-button {
  position: sticky;
  bottom: 20px;
}

.left-content-button + .left-content-button {
  margin-top: 10px;
}

@media (min-width: 1240px) {
  .left-content-button {
    flex: 1 0 100px;
    height: 100px;
    font-size: 20px;
    padding: 0 30px;
  }
}

@media (min-width: 1700px) {
  .left-content-button {
    flex: 1;
  }

  .left-content-button + .left-content-button {
    margin: 0 0 0 10px;
  }
}

@media (max-width: 1000px) and (min-width: 700px) {
  .left-content-button {
    flex: 1;
  }

  .left-content-button + .left-content-button {
    margin: 0 0 0 10px;
  }
}

.left-content-button:hover {
  background: var(--greenHover);
}

.left-content-button .buy,
.left-content-button .price {
  display: block;
}

.left-content-button .buy {
  line-height: 1.4;
}

.left-content-button .price {
  font-family: "Mono";
  font-size: 12px;
  text-align: right;
  font-weight: normal;
}

@media (min-width: 1240px) {
  .left-content-button .price {
    font-size: 16px;
  }
}

.left-content-button .price strong {
  font-weight: bold;
  display: block;
  width: 100%;
  font-family: "Expanded";
  font-size: 15px;
}

.left-content-button.redeem {
  background: #333232;
  color: #6c6b6b;
  pointer-events: none;
}

.left-content-button.redeem span {
  font-size: 12px;
  font-weight: normal;
  font-family: "Mono";
}

@media (min-width: 1240px) {
  .left-content-button.redeem span {
    font-size: 16px;
  }
}

/*
  Right
*/

.right {
  position: relative;
}

.right:after {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(transparent, black);
  content: "";
}

@media (min-width: 1000px) {
  .right {
    width: 50%;
    overflow: auto;
    flex-wrap: wrap;
    display: flex;
  }
}

.right > * {
  width: 100%;
}

.right h2,
.right h3 {
  font-size: 15px;
}

@media (min-width: 1000px) {
  .right h2,
  .right h3 {
    font-size: 20px;
  }
}

.right h2 {
  margin-bottom: 40px;
}

.right-top {
  border-bottom: 1px solid var(--offwhite);
  padding: 0 30px;
  height: 114px;
  align-items: center;
  justify-content: space-between;
  display: none;
}

@media (min-width: 1000px) {
  .right-top {
    padding: 0 60px;
  }
}

@media (min-width: 1000px) {
  .right-top {
    display: flex;
    position: sticky;
    top: 0;
    background: var(--black);
  }
}

.right-top svg {
  width: 65px;
}

.right-top-button {
  background: var(--offwhite);
  height: 50px;
  display: inline-flex;
  padding: 0 20px;
  font-size: 15px;
  text-transform: uppercase;
  font-family: "Expanded", sans-serif;
  font-weight: bold;
  color: var(--black);
  align-items: center;
  padding-top: 2px;
}

.right-top-button:hover {
  background: var(--green);
}

.right-gallery-button {
  text-transform: uppercase;
  font-family: "Expanded", sans-serif;
  font-weight: bold;
  color: var(--offwhite);
  opacity: 48%;
  margin-right: 16px;
}

.right-alpha {
  padding: 35px 30px;
}

@media (min-width: 1000px) {
  .right-alpha {
    padding: 55px 60px;
  }
}

.right-alpha h2 {
  margin-bottom: 40px;
}

@media (min-width: 1000px) {
  .right-alpha-block {
    display: flex;
  }
}

.right-alpha-block + .right-alpha-block {
  margin-top: 40px;
}

.right-alpha-block .icon {
  margin-bottom: 15px;
  width: 50px;
}

@media (min-width: 1000px) {
  .right-alpha-block .icon {
    width: 94px;
    margin: 0;
  }

  .right-alpha-block .text {
    flex: 1;
    margin-left: 30px;
  }
}

.right-alpha-block .text h3 {
  margin-bottom: 10px;
}

.right-alpha-block p .light {
  color: #878187;
  display: block;
}

.right-alpha-block .discord {
  color: var(--green);
  font-family: "Expanded";
  font-weight: bold;
  font-size: 12px;
  display: block;
  margin-top: 5px;
}

.right-alpha-block .discord:hover {
  color: var(--greenHover);
}

.right-alpha-block .discord img {
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 5px;
  width: 18px;
}

.right-founding {
  padding: 35px 30px;
  border-top: 1px solid var(--offwhite);
  font-size: 15px;
}

@media (min-width: 1000px) {
  .right-founding {
    font-size: 18px;
    padding: 55px 60px;
  }
}

.right-founding h2 {
  margin-bottom: 20px;
}

.right-founding p + p {
  margin-top: 25px;
}

.right-faq {
  padding: 35px 30px;
  border-top: 1px solid var(--offwhite);
  font-size: 15px;
}

@media (min-width: 1000px) {
  .right-faq {
    font-size: 18px;
    padding: 55px 60px;
  }
}

.right-faq h2 {
  margin-bottom: 30px;
}

.right-faq-question + .right-faq-question {
  margin-top: 20px;
}

.right-faq-question header {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.right-faq-question header h3 {
  font-size: 15px;
  flex: 1;
}

@media (min-width: 1000px) {
  .right-faq-question header h3 {
    font-size: 20px;
    flex: 1;
  }
}

.right-faq-question header svg path:last-of-type {
  transition: 0.2s opacity;
}

.right-faq-question.active header svg path:last-of-type {
  opacity: 0;
}

.right-faq-question header svg {
  width: 20px;
}

@media (min-width: 1000px) {
  .right-faq-question header svg {
    width: 26px;
  }
}

.right-faq-question-body {
  display: none;
}

.right-faq-question-body-inner {
  padding: 20px 0;
}

.right-loop {
  padding: 35px 30px;
  border-top: 1px solid var(--offwhite);
}

@media (min-width: 1000px) {
  .right-loop {
    border-right: 1px solid var(--offwhite);
    padding: 65px 60px;
    border-bottom: 1px solid var(--offwhite);
  }
}

@media (min-width: 1440px) {
  .right-loop {
    width: calc(100% - 280px);
  }
}

.right-loop h2 {
  margin-bottom: 20px;
}

.right-loop-fields {
  display: flex;
}

.klaviyo_messages {
  margin-top: 15px;
  font-size: 13px;
  color: var(--offwhite);
}

.right-loop input {
  flex: 1;
  padding: 0 20px;
  line-height: 66px;
  border: 1px solid var(--offwhite);
  color: var(--offwhite);
  padding-top: 2px;
}

@media (min-width: 1000px) {
  .right-loop input {
    margin-right: 10px;
    font-size: 18px;
  }
}

.right-loop input::-webkit-input-placeholder,
.right-loop input::placeholder,
.right-loop input:-ms-input-placeholder {
  color: var(--offwhite);
}

.right-loop button {
  background: white;
  padding: 0 20px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1600px) {
  .right-loop button {
    padding: 0 35px;
  }
}

.right-loop button:hover {
  background: var(--green);
}

.right-loop p {
  color: var(--gray);
  font-size: 15px;
  margin-top: 15px;
}

@media (min-width: 1000px) {
  .right-loop p {
    font-size: 18px;
  }
}

.right-connect {
  padding: 35px 30px;
  border-top: 1px solid var(--offwhite);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 1000px) {
  .right-connect {
    flex-direction: column;
    align-items: flex-start;
    padding: 65px 60px;
    border-bottom: 1px solid var(--offwhite);
  }
}

@media (min-width: 1440px) {
  .right-connect {
    width: 280px;
  }
}

.right-connect h2 {
  margin: 0;
}

@media (min-width: 1000px) {
  .right-connect h2 {
    margin-bottom: 15px;
  }
}

.right-connect ul {
  display: inline-flex;
  align-items: center;
}

.right-connect li + li {
  margin-left: 20px;
}

.left-content-button.buy-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  transform: translateY(100px);
  transition: 0.2s transform;
}

.left-content-button.buy-button.active {
  left: 20px;
  transform: none;
}

@media (min-width: 1000px) {
  .buy-button {
    display: none;
  }
}

.spinner {
  width: 40px;
  height: 40px;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
