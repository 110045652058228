.home-cards-row {
  @media (min-width: $xlargebp) {
    display: flex;
  }

  & + & {
    margin-top: 24px;

    @media (min-width: $xlargebp) {
      margin-top: 32px;
    }
  }

  .home-card + .home-card {
    margin-top: 24px;

    @media (min-width: $xlargebp) {
      margin: 0 0 0 32px;
    }
  }
}

.home-cards-grid {
  gap: 24px;
  display: grid;

  @media (min-width: $xlargebp) {
    display: flex;
    flex-direction: column;
    height: 1280px;
    flex-wrap: wrap;
    gap: 32px;
  }
}
