.redeemModal {
  max-width: 683px;
  flex: 1 1 683px;
}

.buttons {
  padding-left: 55px;
  position: relative;

  & > button {
    width: 100%;
    position: relative;
    border-radius: 999px;

    &::after {
      content: " ";
      position: absolute;
      top: 50%;
      left: -55px;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      background: inherit;
      border: inherit;
      transform: translateY(-50%);
    }

    &:disabled::after {
      background: var(--green);
    }

    &.completedButton::after {
      border: 1px solid rgba(255, 255, 255, 0.55);
      /* Needs to be in src because the maintainers of create-react-app are foolish people: https://github.com/facebook/create-react-app/issues/9937 */
      background: url("../icons/checkmark.svg") no-repeat center center,
        var(--black);
    }
  }

  & > button:first-child {
    margin-bottom: 30px;
  }

  &::before {
    content: "";
    width: 1px;
    height: 120px;
    border-left: 1px dashed #8c8c8c;
    position: absolute;
    left: 12px;
    top: 40px;

    @media (min-width: 1240px) {
      height: 125px;
    }
  }
}

.error {
  margin-top: 10px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spinner 1s linear infinite;
}

.loadingButton {
  justify-content: space-between;
}
