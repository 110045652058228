// Colors
$bg: #0a1b11;
$lightBlue: #e8feff;
$green: #2bff88;
$greenHover: #20cf6c;
$white: #fff;
$lightGreen: #b0d7c1;
$darkGray: #0a1b11;
$border: rgba(255, 255, 255, 0.2);
$red: #ff8585;
$orange: #ffc759;
$purple: #da8cff;

// Fonts
$extended: "extended", sans-serif;
$expanded: "expanded", sans-serif;
$mono: "mono", monospace;

$fadeOff: left 0s 0.15s, opacity 0.15s;
$fadeOn: left 0s, opacity 0.15s;

// Breakpoints
$smallbp: 640px;
$largebp: 960px;
$xlargebp: 1100px;
$xxlargebp: 1340px;
