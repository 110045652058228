

.nameInput {
  display: flex;

  margin: 24px 0;

  & > * {
    border: 1px solid #5E5961;
    padding: 12px 15px;
  }

  & > :first-child {
    border-right: none;
  }

  input {
    color: var(--offwhite);
  }
}

.bioInput {
  border: 1px solid #5E5961;
  width: 100%;
  font-family: 'Mono', monospace;
  padding: 15px 20px;
  font-size: 18px;
  resize: none;
  margin-bottom: 16px;
  height: 6em;

  color: var(--offwhite);

  @media (max-width: 900px) {
    height: 14em;
  }
}

.registerButton {
  width: 100%;
  text-align: center;
  justify-content: center;
  height: 56px;
  font-size: 19px;

  @media (max-width: 900px) {
    height: 40px; 
    font-size: 15px;
  }
}

.modal {
  padding: 60px 55px;
  flex: 0 1 650px;

  img:first-child {
    top: 35px;
    right: 35px;
  }

  @media (max-width: 900px) {
    padding: 40px 25px;

    img:first-child {
      top: 20px;
      right: 20px;
    }
  }
}