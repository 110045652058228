
.header {
  border-bottom: 1px solid var(--offwhite);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  width: 100%;
  position: relative;
  z-index: 25;

  @media (max-width: 1000px) {
    padding: 20px;

    img {
      height: 15px;
    }
  }
}

.mobileMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  min-height: 100px;
  z-index: 100;
  color: #fff;
  background-color: #000;
  display: none;
  border-bottom: 1px solid var(--offwhite);

  &.open {
    display: block;
  }
}

.mobileOverlay {
  position: fixed;
  top: 21px;
  left: 21px;
  right: 21px;
  bottom: 0;

  background-color: rgba(0,0,0, 0.5);
  display: none;

  &.open {
    display: block;
  }

  z-index: 15;
}

.children {
  display: flex;
  align-items: center;
}

.headerLink {
  color: var(--offwhite);
  opacity: 48%;
  text-transform: uppercase;
  font-family: 'Expanded', sans-serif;
  margin: 0 20px;
  font-size: 15px;

  &.bold {
    font-weight: bold;
    opacity: 1;
  }

  &.mono {
    font-family: 'Mono', monospace;
    opacity: 1;
    color: #fff;
  }

  @media (max-width: 900px) {
    display: none;
  }
}

.mobileLink {
  color: var(--offwhite);
  text-transform: uppercase;
  font-family: 'Expanded', sans-serif;
  display: block;

  padding: 15px;
  border-bottom: 1px solid var(--offwhite);

  &:first-child {
    border-top:  1px solid var(--offwhite);
  }
}

.profileButton {
  text-align: center;
  justify-content: center;
  height: 60px;
  font-size: 13px;

  &.connectButton {
    background-color: var(--offwhite);
    margin-left: 8px;

    @media (max-width: 900px) {
      display: none;
    }
  }

  &.mobileButton {
    background-color: var(--offwhite);
    margin: 15px;
    width: calc(100% - 30px);
  }
}

.mobileMenuTrigger {
  display: none;
  @media (max-width: 900px) {
    display: block;
    cursor: pointer;
  }
}