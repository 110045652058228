.home-hero {
  padding: 40px 0 80px;

  @media (min-width: $largebp) {
    padding: 80px 0 150px;
  }

  h1 {
    color: $green;
    font-size: 34px;
    max-width: 460px;

    @media (min-width: $smallbp) {
      font-size: 50px;
      max-width: none;
      width: calc(33.33333% - 21.333px);
    }

    @media (min-width: $xlargebp) {
      font-size: 60px;
    }
  }
}

.home-hero-flex {
  @media (min-width: $largebp) {
    display: flex;
  }
}

.home-hero-text {
  max-width: 570px;
  margin-top: 25px;

  @media (min-width: $largebp) {
    margin-top: 180px;
    margin-left: 32px;
  }

  p {
    color: $white;
    font-size: 21px;

    @media (min-width: $xlargebp) {
      font-size: 24px;
    }
  }
}

.home-hero-buttons {
  margin-top: 40px;

  .button {
    width: 100%;

    @media (min-width: $smallbp) {
      width: auto;
    }
  }

  .button + .button {
    margin-top: 10px;

    @media (min-width: $smallbp) {
      margin: 0 0 0 10px;
    }
  }
}
