.container {
  padding: 45px;
  display: flex;
  flex-direction: column;


  @media (max-width: 900px) {
    padding: 0;
  }
}

.title {
  text-transform: uppercase;
  color: var(--offwhite);
  opacity: .3;
  font-family: 'Expanded', sans-serif;
  margin: 0 0 45px;

  @media (max-width: 900px) {
    margin: 0;

    opacity: 1;
    font-weight: 600;

    padding: 15px 20px;
    border-bottom: 1px solid var(--offwhite);
  }
}

.track {
  flex: 1 0 auto;
  position: relative;

  &::after {
    content: '';
    height: calc(100% - 45px);
    width: 1px;
    position: absolute;
    left: 14px;
    border-left: 4px solid rgba(241, 230, 243, 0.08); 
  }

  @media (max-width: 900px) {
    overflow: hidden;

    &::after {
      height: calc(100% - 40px);
      left: 35px;
      top: 20px;
      z-index: 0;
    }
  }
}

.trackStop {
  display: none;
  @media (max-width: 900px) {
    z-index: 5;
    position: relative;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 85%;
    padding-left: 20px;
    background-color: #272328;
    margin-top: 20px;
    height: 35px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    color: rgba(255, 255, 255, 0.4);
  }
}

.trackItems {
  @media (max-width: 900px) {
    padding: 20px;
  }
}

.item {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;

  p {
    font-weight: bold;
    font-size: 15px;
    span {
      font-weight: normal;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.45);
    }
  }
}

.image {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: #272328;
  background-size: 35%;
  background-position: center center;
  background-repeat: no-repeat;

  margin-right: 15px;
}

