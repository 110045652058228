
.badgeGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr 50px;
  grid-gap: 1px;
  background-color: var(--offwhite);
  
  & > div {
    background-color: var(--black);
  };
}

.controls {
  grid-column-start: 1;
  grid-column-end: 4;
  color: var(--offwhite);

  button {
    color: var(--offwhite);
  }

  display: flex;
  justify-content: center;
  align-items: center;
}


.placeholder {
  display: flex; 
  justify-content: center;
  align-items: center;
  padding: 10px 0;

  &::after {
    content: '';

    width: 75px;
    height: 75px;

    border-radius: 100%;
    border: 2px solid rgba(241, 230, 243, 0.12);
    background-color: rgba(241, 230, 243, 0.06);
  }
}

.item {
  position: relative;

  height: 99px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    width: 75px;
    height: 75px;
  
    border-radius: 100%;  
  }
}

.navButton {
  cursor: pointer;
  margin: 0 15px;
  
  height: 20px;
  
  &, * {
    transition: stroke 150ms ease-in;
    stroke: var(--offwhite);
  }


  &.disabled, &.disabled * {
    cursor: auto;
    stroke: #373836;
  }
}

.dotButton {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: rgba(196, 196, 196, 0.2);
  transition: background-color 150ms ease-in;
  cursor: pointer;
  margin: 0 5px;

  &.disabled {
    cursor: auto;
    background-color: var(--offwhite);
  }
}