* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100vw;
}

@keyframes flair {
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, -60%) scale(1.5) rotate(32deg);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.blurs {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  max-width: none;
  width: 2900px;
  //animation: 24s infinite flair;

  @media (min-width: $largebp) {
    left: 50%;
  }
}

body {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  font-family: $expanded;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg;
  color: $lightBlue;
  font-family: $expanded;
  background-image: url("../../static/static.gif");
  background-size: 400px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: $expanded;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

input,
select,
button,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: transparent;
  outline: none;
  font-family: $expanded;
  font-size: 16px;
  border-radius: 0;
}

input[type="email"],
input[type="text"],
input[type="password"],
input[type="search"] {
  display: block;
  width: 100%;
}

a,
button,
label,
input[type="submit"],
input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}

img,
svg,
video {
  display: block;
  max-width: 100%;
}
