.home-card {
  background: linear-gradient(136.87deg, rgba(66, 75, 70, 0.27) 4.36%, rgba(72, 83, 76, 0.27) 100.67%);
  border-radius: 20px;
  overflow: hidden;
  display: block;
  background-color: #202e25;
  background-size: cover;

  @media (min-width: $xlargebp) {
    display: flex;
    width: calc(33.3333% - 21.3px);
  }

  &.flex {
    @media (min-width: $xlargebp) {
      flex: 1;
      min-height: 780px;
    }
  }

  &.small {
    @media (min-width: $xlargebp) {
      height: 450px;
    }
  }
}

.home-card-inner {
  padding: 20px;
  display: block;

  @media (min-width: $xlargebp) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: $xxlargebp) {
    padding: 40px;
  }
}

.home-card-subtext {
  color: $darkGray;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  background: $green;
  border-radius: 10px;
  display: inline-block;
  margin-bottom: 20px;
  line-height: 31px;
  padding: 0 16px;
  padding-top: 3px;

  &.orange {
    background: $orange;
  }

  &.red {
    background: $red;
  }

  &.purple {
    background: $purple;
  }

  &.blue {
    background: #52eaff;
  }
}

.home-card-title {
  color: $white;
  text-transform: uppercase;
  font-size: 24px;

  @media (min-width: $smallbp) {
    font-size: 27px;
  }

  @media (min-width: $xxlargebp) {
    font-size: 34px;
  }
}

.home-card-p {
  color: $lightBlue;
  margin-bottom: 20px;
  font-size: 16px;
  flex: 1;
  display: flex;
  align-items: center;

  @media (min-width: $xlargebp) {
    font-size: 18px;
  }

  .home-card-image + & {
    flex: initial;
  }
}

.home-card-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 0 auto 0;
}

.home-card-image-relative {
  position: relative;

  img {
    margin: 0 auto;
    width: 100%;
  }

  .blur {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(3);
    filter: blur(15px);
    z-index: -1;
    opacity: 50%;
  }
}

.home-card-buttons {
  display: flex;
  align-items: center;
  gap: 20px;
}
