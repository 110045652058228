
$formSpacing: 25px;

.formRow {
  margin-bottom: $formSpacing;
}

.splitRow {
  @extend .formRow;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: $formSpacing;
  align-items: start;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;

    & > :not(:last-child) {
      margin-bottom: $formSpacing;
    }
  }
}

.submitButton {
  width: 100%;
}

.error {
  margin-top: 16px;
}