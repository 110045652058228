

.button {
  height: 80px;
  display: flex;
  align-items: center;
  background: var(--green);
  color: var(--black);
  padding: 0 20px;
  font-family: "Expanded", sans-serif;
  font-weight: bold;
  font-size: 15px;
  padding-top: 2px;
  cursor: pointer;
  text-transform: uppercase;

  &.outline {
    background: var(--black);
    color: #666567;
    border: 1px solid #5E5961;
    cursor: auto;
  }

  &:disabled {
    background: rgba(241, 230, 243, 0.18);
    border: none;
    color: rgba(255, 255, 255, .28);
    cursor: auto;
  }

  &:not(.small) {
    @media (min-width: 1240px) {
      flex: 1;
      height: 100px;
      font-size: 20px;
      padding: 0 30px;
    }
  }
}