
.token {
  background-color: var(--black);

  .bust {
    width: 100%;
  }
}

.tokenName {
  text-transform: uppercase;
  color: #fff;
  flex: 0 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tokenInfo {
  padding: 20px;
  border-top: 1px solid var(--offwhite);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tokenPoaps {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-left: 12px;
  flex: 0 0 auto;
}

.tokenPoap {
  width: 40px;
  margin-right: -20px;
  position: relative;
  left: -20px;
  border: 1px solid var(--offwhite);
  border-radius: 35px;
}